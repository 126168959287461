<template>
  <app-dropdown-filter
    v-model="model"
    icon="ph:calendar-blank"
    item-text="label"
    item-value="value"
    :items="items"
    :label="$t('course.session.date_filter.label')"
    :placeholder="$t('course.session.date_filter.placeholder')"
  >
    <template #selection>
      <span class="font-semibold">{{ label }}</span>
    </template>
  </app-dropdown-filter>
</template>

<script lang="ts" setup>
import { addDays, endOfDay, startOfDay } from "date-fns";

const properties = defineProps<{
  from?: string;
  to?: string;
}>();

const emit = defineEmits<{
  (event: "update:from", payload?: string): void;
  (event: "update:to", payload?: string): void;
}>();

const { t } = useI18n();

const PERIOD_DEFINITIONS = {
  NEXT_30_DAYS: {
    label: t("course.session.date_filter.period_labels.next_30_days"),
    from: startOfDay(new Date()).toISOString(),
    to: endOfDay(addDays(new Date(), 31)).toISOString(),
  },
  NEXT_60_DAYS: {
    label: t("course.session.date_filter.period_labels.next_60_days"),
    from: startOfDay(new Date()).toISOString(),
    to: endOfDay(addDays(new Date(), 60)).toISOString(),
  },
};

const items = Object.entries(PERIOD_DEFINITIONS).map(([key, value]) => {
  return {
    label: value.label,
    value: key,
  };
});

type Period = keyof typeof PERIOD_DEFINITIONS;

const model = computed<Period | undefined>({
  get() {
    if (!properties.from && !properties.to) return;

    return Object.entries(PERIOD_DEFINITIONS).find(([, { from, to }]) => {
      return properties.from === from && properties.to === to;
    })?.[0] as Period;
  },
  set(value) {
    if (!value) {
      emit("update:from");
      emit("update:to");
      return;
    }

    const definition = PERIOD_DEFINITIONS[value];

    emit("update:from", definition.from);
    emit("update:to", definition.to);
  },
});

const label = computed(() => {
  if (!model.value) return;

  return PERIOD_DEFINITIONS[model.value]?.label;
});
</script>
