<template>
  <div class="bg-primary-50 py-sections" data-cy-no-results>
    <app-page-wrapper
      class="grid justify-items-center gap-6 text-center"
      tag="section"
    >
      <app-image
        alt=""
        aria-hidden="true"
        src="/images/illustrations/search_no_results.svg"
      />
      <h1>
        <span class="mb-2 block text-2xl font-extrabold">{{
          $t("search.page.no_results.title")
        }}</span>
        <span class="text-lg">{{ $t("search.page.no_results.subtitle") }}</span>
      </h1>

      <app-button :href="topic_proposal" size="lg">{{
        $t("search.page.no_results.cta")
      }}</app-button>
    </app-page-wrapper>
  </div>
</template>

<script lang="ts" setup>
const {
  public: {
    typeforms: { topic_proposal },
  },
} = useRuntimeConfig();
</script>
