import type { LocationQuery } from "vue-router";

import type { CourseFilters } from "~/business-areas/course/composables/course-card-list.hook";

export type SearchPageFilters = Pick<
  CourseFilters,
  "to" | "from" | "lang" | "tags" | "duration"
>;

export function useSearchPageFiltersActions(filters: Ref<SearchPageFilters>) {
  const { $analytics } = useNuxtApp();

  const reset = () => {
    $analytics.track("search_reset");
    Object.keys(filters.value).forEach((key) => {
      filters.value[key as keyof typeof filters.value] = undefined;
    });
  };

  const hasActiveFilters = computed(() => {
    return numberOfActiveFilters.value > 0;
  });

  const numberOfActiveFilters = computed(() => {
    return Object.values(filters.value).filter((value) => {
      if (Array.isArray(value)) return value.length > 0;

      return value !== undefined && value !== null;
    }).length;
  });

  return {
    reset,
    hasActiveFilters,
    numberOfActiveFilters,
  };
}

function toSearchPageFilter(query: LocationQuery) {
  return {
    from: query.from as string,
    to: query.to as string,
    lang: query.lang as string,
    tags: toArray((query.tags ?? []) as string[]),
    duration: query.duration as string,
  };
}

export function useSearchPageFiltersData() {
  const { $analytics, $on } = useNuxtApp();
  const query = useRoute().query;

  const filters = reactive<SearchPageFilters>(toSearchPageFilter(query));

  watch(
    () => filters,
    () => {
      $analytics.track("search_requested", filters);
      useRouter().push({ query: filters });
    },
    { deep: true },
  );

  if (import.meta.client) {
    $on("search_requested", (query: SearchPageFilters) => {
      filters.tags = query.tags;
    });
  }

  return {
    filters,
  };
}
