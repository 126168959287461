<template>
  <app-page-filter-bar>
    <div class="flex flex-wrap items-center gap-4">
      <tag-filter v-model="filters.tags" data-cy-tag-filter />

      <course-session-date-filter
        v-model:from="filters.from"
        v-model:to="filters.to"
        class="hidden md:block"
        data-cy-session-date-filter
      />

      <course-language-filter
        v-model="filters.lang"
        class="hidden md:block"
        data-cy-lang-filter
      />

      <course-format-filter
        v-model="filters.duration"
        class="hidden md:block"
      />
    </div>

    <app-pop-transition>
      <app-button
        v-show="hasActiveFilters"
        class="hidden md:block"
        color="primary"
        variant="tonal"
        @click="reset"
      >
        {{ $t("search.page.filters.reset") }}
      </app-button>
    </app-pop-transition>

    <div class="relative md:hidden">
      <app-icon-button
        :class="{ 'text-primary before:bg-primary-100': hasActiveFilters }"
        icon="ph:sliders"
        :label="$t('search.page.filters.open_dialog')"
        @click="toggleFilterDialog(true)"
      />
      <span
        v-show="hasActiveFilters"
        class="absolute -right-2 -top-2 grid h-4 w-4 place-content-center rounded-full bg-primary-900 text-xs font-semibold text-white"
        >{{ numberOfActiveFilters }}</span
      >
    </div>

    <search-filters-dialog
      v-if="showFilterDialog"
      v-model="filters"
      v-model:open="showFilterDialog"
      :total-number-of-results="totalNumberOfResults"
    />
  </app-page-filter-bar>
</template>

<script lang="ts" setup>
import type { SearchPageFilters } from "~/pages/search/_includes/composables/search-page-filters.data";
import { useSearchPageFiltersActions } from "~/pages/search/_includes/composables/search-page-filters.data";

const SearchFiltersDialog = defineAsyncComponent(
  () => import("./search-filters-dialog.vue"),
);

const properties = defineProps<{
  modelValue: SearchPageFilters;
  totalNumberOfResults?: number;
}>();

const filters = useVModel(properties, "modelValue");
const { hasActiveFilters, reset, numberOfActiveFilters } =
  useSearchPageFiltersActions(filters);

const [showFilterDialog, toggleFilterDialog] = useToggle();
</script>
